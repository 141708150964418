import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { navigate } from "gatsby"
import queryString from "query-string"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import SimpleLayout from "../components/simpleLayout"
import UserContext from "../context/userContext"
import { api } from "../settings"

export default function ChangePassword({
  location,
}: {
  location: WindowLocation
}) {
  const { state, dispatch } = useContext(UserContext)

  const parsed = queryString.parse(location.search)
  const activateCode = parsed.id
  const [codeValid, setCodeValid] = useState(false)
  useEffect(() => {
    if (activateCode) {
      setCodeValid(true)
    }
  }, [activateCode])

  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty },
    errors,
  } = useForm({ mode: "onBlur" })
  const [isLoading, setIsLoading] = useState(false)
  const login = useCallback(
    (x: any) => {
      setIsLoading(true)

      fetch(api + "/api/UserReset", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.x
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "error", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          id: activateCode ?? x.id,
          password: x.password,
        }), // body data type must match "Content-Type" header
      })
        .then(a => a.text())
        .then(a => {
          if (a === "true") {
            navigate("/login")
          } else {
            alert(a)
          }
        })
        .finally(() => setIsLoading(false))
    },
    [state, dispatch]
  )

  const passwordCurrent = watch("password", "")

  return (
    <SimpleLayout title="Login" location={location}>
      <Flex width="full" align="center" justifyContent="center">
        <Box p={2}>
          <Box textAlign="center">
            <Heading>Pas uw wachtwoord aan</Heading>
          </Box>
          <Box my={4} textAlign="left">
            <form onSubmit={handleSubmit(login)}>
              <>
                <FormControl mt={6}>
                  <FormLabel>Wachtwoord (minimaal zes tekens)</FormLabel>
                  <Input
                    type="password"
                    placeholder="*******"
                    name="password"
                    ref={register({
                      minLength: 6,
                    })}
                    isRequired
                  />
                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  mt={6}
                  isInvalid={errors.passwordRepeat != undefined}
                >
                  <FormLabel>Herhaal wachtwoord</FormLabel>
                  <Input
                    type="password"
                    placeholder="*******"
                    name="passwordRepeat"
                    ref={register({
                      validate: value =>
                        value === passwordCurrent ||
                        "Wachtwoorden komen niet overeen.",
                    })}
                    isRequired
                  />
                  <FormErrorMessage>
                    {errors.passwordRepeat?.message}
                  </FormErrorMessage>
                </FormControl>
              </>

              <Button width="full" mt={4} type="submit" isLoading={isLoading}>
                Reset wachtwoord
              </Button>
            </form>
          </Box>
        </Box>
      </Flex>
    </SimpleLayout>
  )
}
